let host = "https://login.lidercamargo.com"
let api_host = "https://api.lidercamargo.com/store"
let client_id = "1059aqmebvm115iq9cpok4pcse"
let h_login = "https://pedidos.lidercamargo.com/login"
let h_logout = "https://pedidos.lidercamargo.com/logout"
let basic = "Basic MTA1OWFxbWVidm0xMTVpcTljcG9rNHBjc2U6MXFnMmduY3NrN25xdGRtNW1nYm5xNXZ0YXVrcXV0N2xtYnBoM2lqa2RibWloNWN1cTd2cQ=="
let bucket_host = 'https://co-lider-camargo-documents.s3.amazonaws.com'

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    host = "https://authtest.lidercamargo.com"
    api_host = "https://apitest.lidercamargo.com/store"
    client_id = "43voo4e7tmktggdo67uip5trll"
    h_login = "http://localhost:3000/login"
    h_logout = "http://localhost:3000/logout"
    basic = "Basic NDN2b280ZTd0bWt0Z2dkbzY3dWlwNXRybGw6MTBzdW02a2l1Ym1ldHZuYjl0YTFwaDhkOTJocWp1NDNyM2NucGpnbHM0ZnR2NmRpMjBrdg"
}

let properties = {
    url_login : host + "/login?client_id=" + client_id + "&response_type=code&redirect_uri=" + h_login,
    url_token : host + "/oauth2/token?grant_type=authorization_code&redirect_uri=" + h_login,
    url_token_r : host +"/oauth2/token?grant_type=refresh_token&client_id="+client_id,
    url_logout : host+ "/logout?client_id=" + client_id + "&logout_uri=" + h_logout,
    url_user_inf : host + "/oauth2/userInfo",
    url_revoke: host + "/oauth2/revoke",
    basic,
    api_host,
    bucket_host
}
export default properties;