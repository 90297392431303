import {Component, useEffect, useState} from "react";
import {Button, Card, Col, FormControl, InputGroup, Row} from "react-bootstrap";
import BackImage from "../assets/img/logo512.png"
import {FiMinus, FiPlus} from "react-icons/fi";
import {AiFillDelete} from "react-icons/ai";

class ProductCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: BackImage,
            is_added: false,
            amount: 0,
        }
    }

    componentDidMount() {
        this.calculateAmountSaved();
        this.downloadImage();
    }

    render() {
        let product = this.props.product
        return (
            <Card //style={{height: '26rem'}}
                className={"shadow-lg mb-4 bg-white rounded"}
            >
                <Card.Body>
                    <Row className={"mb-2"}>
                        <Col xs={"3"} sm={4} md={3} lg={4} xl={3} xxl={4} >
                            <Card.Img src={this.state.image} style={{height: "5rem", width: "5rem"}}
                            />
                        </Col>
                        <Col xs={9} sm={8} md={9} lg={8} xl={9} xxl={8}>
                            <Card.Title className={"overflow-hidden"}
                                        style={{maxHeight: "3rem", height: "3rem"}}
                            >
                                {product.name}
                            </Card.Title>
                            <Row>
                                <Col>
                                    <small className="text-muted">Ref: <b>{product.code}</b></small>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <small className="text-muted">P/u</small>
                                </Col>
                                <Col style={{textAlign: 'right'}}>
                                    <b>${new Intl.NumberFormat('de-DE').format(
                                        this.getDefaultValue(product)
                                    )}
                                    </b>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className={"text-center"}>
                        {this.state.is_added ? (
                            <InputGroup>
                                {this.state.amount === 1 || this.state.amount === "1" ? (
                                    <Button variant={"danger"}
                                            onClick={() => this.validateValue(0)}
                                    >
                                        <i><AiFillDelete/></i>
                                    </Button>
                                ) : (
                                    <Button
                                        style={{backgroundColor: '#c0ce00', borderColor: '#c0ce00', color: "black"}}
                                            onClick={() => this.validateValue(parseInt(this.state.amount) - 1)}
                                    >
                                        <i><FiMinus/></i>
                                    </Button>
                                )}
                                <FormControl
                                    type={"number"}
                                    className={"text-center"}
                                    //defaultValue={this.state.amount}
                                    value={this.state.amount}
                                    onChange={(event) => {
                                        this.validateValue(event.target.value);
                                    }}
                                />
                                <Button style={{backgroundColor: '#c0ce00', borderColor: '#c0ce00', color: "black"}}
                                        onClick={() => this.validateValue(parseInt(this.state.amount) + 1)}
                                >
                                    <i><FiPlus/></i>
                                </Button>
                            </InputGroup>
                        ) : (
                            <Button style={{backgroundColor: '#c0ce00', borderColor: '#c0ce00', color: "black"}}
                                    onClick={() => this.validateValue(1)}
                            >
                                Agregar
                            </Button>
                        )}
                    </Row>
                </Card.Body>
            </Card>
        )
    }

    getDefaultValue = (product) => {
        if (product.sales_list && product.sales_list.length > 0) {
            if (product.sales_list[product.sales_list_default]) {
                if (product.iva) {
                    return parseInt(product.sales_list[product.sales_list_default].value * (1 + (product.iva / 100)))
                }
                return product.sales_list[product.sales_list_default].value
            }
            return product.sales_list[0].value
        }
        return 0
    }

    validateValue = (value) => {
        if (value > 0) {
            this.setState({
                amount: parseInt(value),
                is_added: true,
            });
            this.addProductToOrder(parseInt(value))
        }
        else {
            if(value === ""){
                this.setState({
                    amount: "",
                    is_added: true,
                });
            } else{
                this.setState({
                    amount: 0,
                    is_added: false,
                });
                this.removeProductInOrder();
            }
        }
    }

    calculateAmountSaved = () => {
        let order = this.props.order;
        if (order && order.products && order.products.length > 0) {
            for (let product of order.products) {
                if (product.code === this.props.product.code) {
                    this.setState({
                        amount: product.amount,
                        is_added: true,
                    })
                }
            }
        }
    }

    addProductToOrder(amount) {
        let order = this.props.order;
        if (!order || !order.products || !order.products.length > 0) {
            order['products'] = []
        }
        let products = order.products
        let is_found = false
        for (let product of products) {
            if (product.code === this.props.product.code) {
                is_found = true
                product.amount = amount
                product.total = amount * product.value
            }
        }
        if (!is_found) {
            let default_value = this.getDefaultValue(this.props.product)
            products.push({
                id: products.length,
                code: this.props.product.id,
                amount: amount,
                name: this.props.product.name,
                value: default_value,
                iva: this.props.product.iva,
                weight: this.props.product.weight,
                option: true,
                total: default_value * amount,
                url_image: this.props.product.url_image
            })
        }
        this.props.setOrder(order);
    }

    removeProductInOrder() {
        let order = this.props.order;
        if(order && order.products && order.products.length > 0){
            let newProducts = []
            for (let product of order.products) {
                if(product.code !== this.props.product.code){
                    newProducts.push(product)
                }
            }
            order.products = newProducts;
            this.props.setOrder(order);
        }
    }

    downloadImage = () => {
        if(this.props.product['url_image']){
            this.props.loadGetBucket("/images/"+this.props.product['url_image'], this.loadImage, this.loadErrorBucket);
        }
    }

    loadImage = (image) => {
        this.setState({
            image: image
        });
    }

    loadErrorBucket = (error) => {
        console.log(error);
    }

}

export default ProductCard;