import {Component} from "react";
import {Alert, Button, Card, Col, FormSelect, Row} from "react-bootstrap";
import {NavLink, Redirect} from "react-router-dom";
import ModalOrder from "./ModalOrder";

class Checkout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: null,
            disabled: false,
            error: null,
            content: null
        }
    }

    componentDidMount() {
        if (this.props.client) {
            let order = this.props.order;
            order.client = this.props.client;
            order.client_id = this.props.client.id;
            order.from_store = true;
            order.payment_method = 'CONTADO';
            order.advisory= this.props.client.advisory?this.props.client.advisory:"";
            this.props.setOrder(order);
        }
    }

    render() {
        let order = this.props.order;
        let total = 0;
        if (order && order.products && order.products.length > 0) {
            let totals = order.products.map((product) => {
                return product.total
            })
            for (const k of totals) {
                total = total + k
            }
        }
        return (
            <div>
                {this.state.content}
                <ModalOrder {...this.props} show={this.state.modal}
                            onHide={() => this.setModalShow(false)}
                            error={this.state.error}
                />
                <h2 className={"text-center"}>Checkout</h2>
                <hr/>

                <h4>Tus datos</h4>
                {this.props.client ? (
                    <Alert variant={"info"}>
                        <Row>
                            <Col sm={6}>
                                <Row>
                                    <Col>
                                        <b>Cliente: </b>
                                    </Col>
                                    <Col>
                                        {this.props.user['custom:name'] ?
                                            this.props.user['custom:name'] + " " + this.props.user['custom:last_name_v2'] :
                                            this.props.client.name
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={6}>
                                <Row>
                                    <Col>
                                        <b>Identificación: </b>
                                    </Col>
                                    <Col>
                                        {this.props.user['custom:identification_v2'] ? this.props.user['custom:identification_v2'] :
                                            this.props.client.identification
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            {this.props.client.establishment_name ? (
                                <Col sm={6}>
                                    <Row>
                                        <Col>
                                            <b>Establecimiento: </b>
                                        </Col>
                                        <Col>
                                            {this.props.client.establishment_name}
                                        </Col>
                                    </Row>
                                </Col>
                            ) : null}
                            {this.props.client.department || this.props.client.city ? (
                                <Col sm={6}>
                                    <Row>
                                        <Col>
                                            <b>Ciudad: </b>
                                        </Col>
                                        <Col>
                                            {this.props.client.city}/{this.props.client.department}
                                        </Col>
                                    </Row>
                                </Col>
                            ) : null}
                            {this.props.client.address ? (
                                <Col sm={6}>
                                    <Row>
                                        <Col>
                                            <b>Dirección: </b>
                                        </Col>
                                        <Col>
                                            {this.props.client.address}
                                        </Col>
                                    </Row>
                                </Col>
                            ) : null}
                            {this.props.client.phone ? (
                                <Col sm={6}>
                                    <Row>
                                        <Col>
                                            <b>Teléfonos: </b>
                                        </Col>
                                        <Col>
                                            {this.props.client.phone}
                                        </Col>
                                    </Row>
                                </Col>
                            ) : null}
                        </Row>
                    </Alert>
                ) : (
                    <Alert variant={"danger"}>
                        Por favor comunicarse con el administrador para habilitar un cliente activo y asi poder
                        hacer el pedido.
                    </Alert>
                )}
                <hr/>

                <h4>Valor Pedido</h4>

                <Alert>
                    <Row>
                        <Col>
                            Número de artículos en la orden <b>{order?.products?.length}</b>
                        </Col>
                        <Col className={"text-end"}>
                            <b>${new Intl.NumberFormat('de-DE').format(total)}</b>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={"text-end"}>
                            <NavLink to={"/store/order"}>
                                Revisar el carrito
                            </NavLink>
                        </Col>
                    </Row>
                </Alert>
                <hr/>

                <h4>Forma de Pago</h4>
                <Card>
                    <Card.Body>
                        <FormSelect
                            value={order.payment_method}
                            onChange={(event) => this.changePaymentMethodOrder(event)}
                        >
                            <option value={"CONTADO"}>CONTADO</option>
                            <option value={"30DIAS"}>30 DIAS</option>
                            <option value={"15DIAS"}>15 DIAS</option>
                            <option value={"8DIAS"}>8 DIAS</option>
                        </FormSelect>
                    </Card.Body>
                </Card>
                <hr/>
                <Row>
                    <div className={"d-none d-lg-block"}>
                        <Row>
                            <Button size="lg" onClick={() => this.onClickSendOrder()}
                                    disabled={this.state.disabled}
                                    style={{backgroundColor: '#c0ce00', borderColor: '#c0ce00', color: "black"}}
                            >
                                Enviar Pedido
                            </Button>
                        </Row>
                    </div>
                    <div className={"position-fixed bottom-0 d-block d-lg-none start-0"}>
                        <Row>
                            <Button size="lg" onClick={() => this.onClickSendOrder()}
                                    disabled={this.state.disabled}
                                    style={{backgroundColor: '#c0ce00', borderColor: '#c0ce00', color: "black"}}
                            >
                                Enviar Pedido
                            </Button>
                        </Row>
                    </div>
                </Row>
            </div>
        )
    }

    changePaymentMethodOrder = (event) => {
        let value = event.target.value;
        let order = this.props.order;
        order.payment_method = value;
        this.props.setOrder(order);
    }

    setModalShow = (modal) => {
        this.setState({
            modal
        });
    }
    onClickSendOrder = () => {
        this.setModalShow(true);
        this.setState({disabled: true});
        const order = this.props.order;
        let products = [];
        if(order.products.length > 0){
            for (const product of order.products) {
                const new_product = {
                    id: product.id,
                    code: product.code,
                    option: product.option,
                    amount: product.amount,
                    value: product.value,
                    iva: product.iva,
                    name: product.name,
                    weight: product.weight,
                    differential: 0,
                    total: product.total
                }
                products.push(new_product);
            }
        }
        order.products = products;
        this.props.loadPost("/orders", order, this.successCreateOrder, this.loadError);
    }
    successCreateOrder = (order) => {
        if (order) {
            this.setState({
                content: (<Redirect to={"/store/orders/" + order.id}/>)
            });
            this.props.setOrder({});
        } else {
            this.loadError("Orden no creada");
        }
    }

    loadError = (error) => {
        this.setState({
            error
        });
        setTimeout( () => {
            this.setModalShow(false);
            this.setState({disabled: false});
        }, 3000)
    }
}

export default Checkout;