import {Component} from "react";
import {Alert, Badge, Button, Col, Row, Spinner} from "react-bootstrap";
import ProductCard from "../Components/ProductCard";
import {FiShoppingCart} from "react-icons/fi";
import {NavLink} from "react-router-dom";
import ProductsByCategory from "../Components/ProductsByCategory";

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            categories: [],
            error: null,
            ready: false
        }
    }

    componentDidMount() {
        this.loadAllCategories();
    }

    render() {
        return (
            <div>
                <Alert variant={"info"}>
                    Hola {
                    this.props.user['custom:name'] ? this.props.user['custom:name'] : this.props.client.name
                }!
                    <br/>
                    <small className={"text-muted"}>Revisa todo nuestro catálogo y agrega productos al carrito para
                        completar tu compra</small>
                </Alert>
                <Row className="mt-3">
                    {this.state.ready ?
                        this.state.error ? (
                            <Alert variant={"danger"}>
                                <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                <p>
                                    {this.state.error}
                                </p>
                                <hr/>
                                <Button onClick={() => this.loadAllProducts()} variant={"dark"}>
                                    Intentar nuevamente
                                </Button>
                            </Alert>
                        ) : (
                            <ProductsByCategory {...this.props} products={this.state.products}
                                                categories={this.state.categories}/>
                        )
                        : (
                            <div className={"text-center"}>
                                <Spinner animation={"border"} variant={"success"}></Spinner>
                            </div>
                        )}

                </Row>
                <Row>
                    {this.props.order && this.props.order.products && this.props.order.products.length > 0 ? (
                        <div className={"position-fixed bottom-0 d-block d-lg-none start-0"}>
                            <Row>
                                <NavLink to={"/store/order"}
                                         className={"text-decoration-none btn btn-secondary btn-lg"}>
                                    <i><FiShoppingCart/></i> Ver Carrito <Badge
                                    bg="warning">{this.props.order.products.length}</Badge>
                                </NavLink>
                            </Row>
                        </div>
                    ) : null}
                </Row>
            </div>
        )
    }

    loadAllCategories = () => {
        this.props.loadGet("/categories?type=product", this.loadContentCategories, this.loadError);
    }

    loadContentCategories = (categories) => {
        this.setState({
            categories: categories
        });
        this.loadAllProducts();
    }

    loadAllProducts = () => {
        this.props.loadGet("/products/" + (this.props.user && this.props.user['custom:client_id'] ? this.props.user['custom:client_id'] : "anonymous"),
            this.loadContent, this.loadError)
    }

    loadContent = (products) => {
        products.sort((a, b) => {
            return a.code - b.code
        });
        this.setState({
            ready: true,
            products
        });

        let categories = this.state.categories;
        for (let i = 0; i < categories.length; i++) {
            for (let j = 0; j < products.length; j++) {
                if (products[j].categories && products[j].categories.length > 0) {
                    for (let k = 0; k < products[j].categories.length; k++) {
                        if (products[j].categories[k].id===categories[i].id) {
                            if(categories[i].products) {
                                categories[i].products.push(products[j]);
                            } else {
                                categories[i].products=[products[j]];
                            }
                        }
                    }
                }
            }
        }
        let otherCategory = {id: "others", name: "Otras Categorías", description: "Todos nuestros productos no clasificados dentro de una categoría específica", products: []}
        for (let i = 0; i < products.length; i++) {
            if (!products[i].categories) {
                otherCategory.products.push(products[i]);
            }
        }
        categories.push(otherCategory);
        this.setState({
            categories: categories
        })
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        });
    }
}

export default Home;